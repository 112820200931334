@import "~antd/dist/antd.css";
html {
  height: auto;
}

#background {
  position: absolute;
  z-index: 0;
  display: block;
  min-height: 50%;
  min-width: 50%;
  color: yellow;
}

#content {
  position: absolute;
  z-index: 1;
}

#bg-text {
  color: lightgrey;
  font-size: 2vw;
  opacity: 0.4;
  transform: rotate(345deg);
  -webkit-transform: rotate(345deg);
}

.vertical-menu {
  height: auto;
  overflow: hidden;
}
.vertical-collpsed {
  min-height: 600px !important;
}

.headtitle {
  color: black;
  font-size: 2vw;
}

@media only screen and (max-width: 600px) {
  .headtitle {
    font-size: 4vw;
    text-align: center;
  }
}
