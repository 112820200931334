body {
  background: "pink";
}

.btnM {
  height: 50px;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 26px;
  background-color: #000000;
  border: 0;
  color: #ffa502;
  font-weight: 500 !important;
  border-radius: 24px;

  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.btnpn {
  height: 40px;
  padding: 6px 14px;
  font-size: 16px;
  line-height: 15px;
  background-color: #000000;
  border: 0;
  color: #ffa502;
  font-weight: 500 !important;
  border-radius: 24px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.btnM:hover {
  background-color: #ffa502;
  color: #000000 !important;
}

.btnpn:hover {
  background-color: #ffa502;
  color: #000000 !important;
}

.animGear {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .animGear {
    flex-direction: column;
  }
}
