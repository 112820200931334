.glow {
  color: #fff;
  text-align: center;
  /* animation: glow; */
  transition: text-shadow 1s;
}

/* text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6; */
.tso {
  text-shadow: 0 0 20px #fff, 0 0 30px white, 0 0 40px white, 0 0 50px white,
    0 0 60px white, 0 0 70px white, 0 0 80px white;
}

.tsn {
  text-shadow: none;
}
