.textContainer {
  height: 70%;
  border: none;
  padding: 0.2em;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  /* padding-right: 1em; */
}
.textpart {
  /* height: 80%; */
  /* padding-top:1em ; */
  font-size: 7em;
  font-weight: 800;
  width: 100%;
}
.textpart p {
  /* padding-top: em; */
  /* letter-spacing: .1em; */
}
.textpart p p {
  display: inline;
  padding: 0px;
}

@keyframes texting {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.divWrap * {
  font-family: inherit;
}

.btnM {
  height: 50px;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 26px;
  background-color: #000000;
  border: 0;
  color: #ffa502;
  font-weight: 500 !important;
  border-radius: 24px;

  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
