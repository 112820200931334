.colorInput{
    background-color: #e1e1e1;
    transition: background-color color .5s;
    padding: .3em .5em ;  
    margin: .3em .3em;;
    margin-right: 4em;
    /* border-radius: 1em; */
    color: #ffffff;
    font-weight: 300;
    font-size: 0.8em;
    width: 90%;
}

.colorInput:hover{
    color: gray;
    background-color: #ececec;
  }
.inputContainer{
    padding-left: 1em;
}